@use "./theme";
@use "./consts";
@use "./global" as *;

.Header {
	@include page-section;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	background-color: theme.$bg;

	position: sticky;
	top: 0;

	.inside {
		padding: 0.875em 1.25em;
		display: flex;
		align-items: center;

		.logo {
			display: block;
			height: 2em;
			filter: brightness(4.4) saturate(0);
			transition: filter consts.$fade-time;

			@include hover {filter: none;}
		}

		.spacer {flex-grow: 1;}
	}

	&::after {@include border-sh(theme.$fg);}
}
